import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import AutoPolicyUpdatesDesktopPNG from "./AutoPolicyUpdatesDesktop.png"
import AutoPolicyUpdatesDesktopWebP from "./AutoPolicyUpdatesDesktop.webp"
import AutoPolicyUpdatesMobilePNG from "./AutoPolicyUpdatesMobile.png"
import AutoPolicyUpdatesMobileWebP from "./AutoPolicyUpdatesMobile.webp"

import { Row, Col, Img } from "./style"
import { ContainerPage, WrapperPage } from "src/componentsV2/ui/layout"
import { Title, SubTitle } from "src/componentsV2/sections/Text"

const AutoPolicyUpdatesContainer = styled(ContainerPage)`
  padding-top: 0px;
  @media (min-width: 768px) {
    padding-top: 80px;
    padding-bottom: 20px;
  }
`

const index = () => {
  return (
    <AutoPolicyUpdatesContainer isBackgroundWhite data-loadable-component="auto-policy-updates">
      <WrapperPage>
        <Row className="items-center">
          <Col className="text-center md:text-left">
            <picture>
              <source srcSet={AutoPolicyUpdatesDesktopWebP} type="image/webp" media="(min-width: 769px)" />
              <source srcSet={AutoPolicyUpdatesDesktopPNG} type="image/jpg" media="(min-width: 769px)" />
              <source srcSet={AutoPolicyUpdatesMobileWebP} type="image/webp" />
              <source srcSet={AutoPolicyUpdatesMobilePNG} type="image/jpg" />
              <Img src={AutoPolicyUpdatesDesktopPNG} width="600" height="412" alt='handbook apu' />
            </picture>
          </Col>
          <Col className="description">
            <Title className="title">
              <div className="block md:hidden">
              Updating policies just
              </div>
              <div className="block md:hidden">
              got easier & smarter
              </div>
              <div className="hidden md:block">
              Updating policies just got easier & smarter
              </div>
            </Title>
            <SubTitle className="px-4 md:px-0 mb-5 md:mb-10">
              Introducing AirMason’s Automated Policy Updates.
              AirMason can now not just create your handbook, but can keep your employee
              handbook policies updated at all times. Leveraging AI, but always overseen
              and reviewed by HR and legal experts.
            </SubTitle>
            <div style={{ width: "150px" }} className="mx-auto md:mx-0">
              <Link to={'/automated-policy-updates'} style={{ textDecoration: "none" }} >
                <div style={{ fontSize: "16px", lineHeight: 1 }} className="bg-blue-darker text-white rounded-full py-4 px-8 font-bold">
                  Learn More
                </div>
              </Link>
            </div>
          </Col>
        </Row>
      </WrapperPage>
    </AutoPolicyUpdatesContainer>
  )
}

export default index
